import {createApp} from "vue";
import router from "./router";
import App from "./App.vue";
import "./registerServiceWorker";
import TableOfContentsEntry from "@/components/menu/TableOfContentsEntry.vue";

createApp(App)
    .use(router)
    .component("TableOfContentsEntry", TableOfContentsEntry)
    .mount("#app");
