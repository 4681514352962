import {Article} from "@/model/Article";

export const werkstoffHolz: Article = {
    id: 9,
    title: "Werkstoff Holz",
    slug: "werkstoff-holz",
    content: [
        {
            content: [
                {
                    type: "multiple_choice",
                    data: {
                        question: "Die Abbildung stellt den Querschnitt durch einen Stamm dar. Wie wird der mit der \"4\" gekennzeichnte Teil bezeichnet?",
                        options: ["Splintholz", "Rinde", "Markröhre", "Kambium", "Kernholz"],
                        answer: ["Markröhre"],
                        image: "img/content/querschnitt.png"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welcher Baum ist ein Nadelholzbaum?",
                        options: ["Eibe", "Esche", "Eiche", "Erle", "Espe"],
                        answer: ["Eibe"]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "In welcher Stammzone findet die Zellteilung statt?",
                        options: ["Bast", "Splintholz", "Markröhre", "Kambium", "Spätholz"],
                        answer: ["Kambium"]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Der Hauptbestandteil des Holzes bildet ...",
                        options: ["Gernstoff", "Zellulose", "Kambium", "Harz", "Sauerstoff"],
                        answer: ["Zellulose"]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welche Holzart hat die größte Dichte?",
                        options: ["Pockholz", "Ahorn", "Eiche", "Kiefer", "Balsa"],
                        answer: ["Pockholz"]
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welcher Wuchsfehler ist abgebildet?",
                        options: ["Spannrückigkeit", "Wundüberwallung", "Exzentrischer Wuchs", "Kernrisse", "Doppelkernigkeit"],
                        answer: ["Exzentrischer Wuchs"],
                        image: "img/content/wuchsfehler.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welche Holzart ist für Gartenmöbel geeignet?",
                        options: ["Rotbuche", "Birke", "Tanne", "Eiche", "Espe"],
                        answer: ["Eiche"]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Unter Frühholz versteht man ...",
                        options: ["schnell wachsendes Holz, dass früh geerntet werden kann.", "Holz, dass im Frühjahr gefällt wird.", "sehr hartes und dunkles Holz.", "Holzteile, die im Frühjahr zuwachsen.", "nur kurz gelagertes Holz."],
                        answer: ["Holzteile, die im Frühjahr zuwachsen."]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welche Zellarten unterscheidet man beim Laubholz?",
                        options: ["Wurzelzellen, Stammzellen, Kronenzellen", "Frühholzzellen und Spätholzzellen", "Fotosynthesezellen und Kambiumzellen", "Markstrahlen und Spiegel", "Stützzellen, Leitzellen, Speicherzellen"],
                        answer: ["Stützzellen, Leitzellen, Speicherzellen"]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welche Holzart ist für den Fensterbau nicht geeignet?",
                        options: ["Meranti", "Eiche", "Rotbuche", "Lärche", "Kiefer"],
                        answer: ["Rotbuche"]
                    }
                }   
            ]
        },
        {
            content: [
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie viel Prozent Holzfeuchte darf das Holz zur Fertigung von Fenstern haben?",
                        options: ["≈ 6 bis 8%", "≈ 8 bis 10%", "≈ 12 bis 15%", "≈ 30%", "≈ 50%"],
                        answer: ["≈ 12 bis 15%"]
                    }
                }   
            ]
        }
    ]
};
