import {Article} from "@/model/Article";
import {derBaum} from "@/data/der-baum";
import {bezeichnungenHolzarten} from "@/data/bezeichnungen-holzarten";
import {schlitzUndZapfen} from "@/data/schlitz-und-zapfen";
import {werkstoffHolz} from "@/data/werkstoff-holz";
import {werkzeuge} from "@/data/werkzeuge";

export const articles: Article[] = [
    derBaum,
    bezeichnungenHolzarten,
    schlitzUndZapfen,
    werkstoffHolz,
    werkzeuge
];
