import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import ArticleView from "@/views/ArticleView.vue";
import AccountView from "@/views/AccountView.vue";
import LoginView from "@/views/LoginView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: ArticleView,
        meta: {
            requiresAuthentication: true
        }
    },
    {
        path: "/artikel/:slug/:slide*",
        name: "Article",
        component: ArticleView,
        meta: {
            requiresAuthentication: true
        }
    },
    {
        path: "/konto",
        name: "Account",
        component: AccountView
    },
    {
        path: "/anmelden",
        name: "Login",
        component: LoginView
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Not-Found",
        component: NotFoundView
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {
            el: ".content",
            top: 0,
            behavior: "auto"
        };
    }
});

router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuthentication && !localStorage.getItem("active_account")) {
        location.href = "/konto";
    }

    next();
});

export default router;
