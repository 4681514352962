const threshold = 20;
export type SwipeDirection = string;
export const SwipeLeft: SwipeDirection = "LEFT";
export const SwipeRight: SwipeDirection = "RIGHT";
export const SwipeUp: SwipeDirection = "UP";
export const SwipeDown: SwipeDirection = "DOWN";

export function swipe(selector: string, direction: SwipeDirection, callback: () => void) {
    const container = document.querySelector(selector);

    if(!container) {
        throw `container element for selector '${selector}' not found`;
    }

    let x: number | null, y: number | null;
    let currentX: number, currentY: number;

    container.addEventListener("touchstart", (e: TouchEventInit) => {
        if(e.touches && e.touches.length) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        }
    });

    container.addEventListener("touchmove", (e: TouchEventInit) => {
        if(x === null || y === null || !e.touches) {
            return;
        }

        currentX = e.touches[0].clientX;
        currentY = e.touches[0].clientY;
    });

    container.addEventListener("touchend", () => {
        if(x === null || y === null) {
            return;
        }

        const moveX = x-currentX;
        const moveY = y-currentY;

        if(direction === SwipeLeft && moveX > 0 && Math.abs(moveX) > threshold && Math.abs(moveX) > Math.abs(moveY)*2 ||
            direction === SwipeRight && moveX < 0 && Math.abs(moveX) > threshold && Math.abs(moveX) > Math.abs(moveY)*2 ||
            direction === SwipeUp && moveY > 0 && Math.abs(moveY) > threshold && Math.abs(moveY) > Math.abs(moveX)*2 ||
            direction === SwipeDown && moveY < 0 && Math.abs(moveY) > threshold && Math.abs(moveY) > Math.abs(moveX)*2) {
            callback();
        }

        x = null;
        y = null;
    });
}
