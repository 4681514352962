import {Article} from "@/model/Article";

export const bezeichnungenHolzarten: Article = {
    id: 4,
    title: "Bezeichnungen Holzarten",
    slug: "bezeichnungen-holzarten",
    content: [
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Laubholz"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/laubholz.png",
                        alt: "Laubholz",
                        caption: "Laubholz"
                    }
                },
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Nadelholz"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/nadelholz.png",
                        alt: "Nadelholz",
                        caption: "Nadelholz"
                    }
                },
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Plattenwerkstoffe"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/plattenwerkstoffe.png",
                        alt: "Plattenwerkstoffe",
                        caption: "Plattenwerkstoffe"
                    }
                }
            ]
        }
    ]
};
