import {Article} from "@/model/Article";

export const schlitzUndZapfen: Article = {
    id: 6,
    title: "Schlitz- und Zapfenverbindung",
    slug: "schlitz-und-zapfen",
    content: [
        {
            content: [
                {
                    type: "paragraph",
                    data: {
                        text: "Im Innenausbau und im Möbelbau werden häufig Hölzer zu Rahmen zusammengefügt. Dieser Rahmen dient dann zur Aufnahme einer Füllung, die, je nach Verwendungszweck, entweder aus Vollholz, Plattenmaterial oder beispielsweise auch Glas bestehen kann. Auf den folgenden Seiten wird dir Herstellung einer Schlitz- und Zapfenverbindung Schritt für Schritt erklärt."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen.png",
                        alt: "Schlitz- und Zapfenverbindung",
                        caption: "Schlitz- und Zapfenverbindung"
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Vorbereitung: Benötigtes Werkzeug"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Neben Gliedermaßstab und Bleistift müssen folgende Werkzeuge bereitgelegt werden:"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen11.jpg",
                        alt: "Anschlagwinkel",
                        caption: "Anschlagwinkel"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen3.jpg",
                        alt: "Streichmaß",
                        caption: "Streichmaß"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen12.jpg",
                        alt: "Schreinerklüpfel",
                        caption: "Schreinerklüpfel"
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen4.jpg",
                        alt: "Stemmeisen",
                        caption: "Stemmeisen"
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Schritt 1: Schreinerdreieck anzeichnen"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Das Schreinerdreieck ist ein über mehrere Holzstücke aufgezeichnetes Dreieck. Das Dreieck zeigt die Zusammengehörigkeit der Holzstücke sowie die Reihenfolge der Stücke untereinander an."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen5.jpg",
                        alt: "Angezeichnetes Schreinerdreieck",
                        caption: "Angezeichnetes Schreinerdreieck"
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Schritt 2: Breite anzeichnen"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Die Breite des Rahmenholzes wird gemessen und auf beiden Rahmenhözern angezeichnet."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen15.jpg",
                        alt: "Die Breite des Rahmenholzes beträgt in diesem Fall 48mm.",
                        caption: "Die Breite des Rahmenholzes beträgt in diesem Fall 48mm."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen13.jpg",
                        alt: "Die gemessene Breite (48mm) wird auf beide Rahmenhölzer übertragen.",
                        caption: "Die gemessene Breite (48mm) wird auf beide Rahmenhölzer übertragen."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen16.jpg",
                        alt: "Die Markierung wird mithilfe des Anschlagwinkels verlängert.",
                        caption: "Die Markierung wird mithilfe des Anschlagwinkels verlängert."
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Schritt 3: Markierung umlaufend übertragen"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Die angezeichnete Linie wird mithilfe des Anschlagwinkels nacheinander auf alle Seiten beider Rahmenhölzer übertragen."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen9.jpg",
                        alt: "Der Anschlagwinkel muss vollständig anliegen, damit die Markierung rechtwinkelig ist.",
                        caption: "Der Anschlagwinkel muss vollständig anliegen, damit die Markierung rechtwinkelig ist."
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Schritt 4: Zapfen und Schlitz anreißen"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Das Streichmaß wird auf ein Drittel der Werkstückstärke eingestellt. In diesem Fall beträgt die Stärke 30mm, welche geteilt durch 3 demnach 10mm ergeben."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen2.jpg",
                        alt: "Die Einstellung des Streichmaßes sollte mit dem Gliedermaßstab gegengeprüft werden.",
                        caption: "Die Einstellung des Streichmaßes sollte mit dem Gliedermaßstab gegengeprüft werden."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen8.jpg",
                        alt: "Die schmalen Seiten der Rahmenhözer werden beidseitig angerissen.",
                        caption: "Die schmalen Seiten der Rahmenhözer werden beidseitig angerissen."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen1.jpg",
                        alt: "Die Anrisse werden mit dem Bleistift nachgezeichnet, um besser sichtbar zu sein.",
                        caption: "Die Anrisse werden mit dem Bleistift nachgezeichnet, um diese besser sichtbar zu machen."
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Schritt 5: Zu entfernendes Material markieren"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Um während der Zerspanung keinen Fehler zu machen, werden die zu entfernenden Teile mit dem Bleistift markiert."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/schlitzundzapfen14.jpg",
                        alt: "Zapfen und Schlitz sind deutlich zu erkennen.",
                        caption: "Zapfen und Schlitz sind deutlich zu erkennen."
                    }
                }
            ]
        }
    ]
};
