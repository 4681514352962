import {Article} from "@/model/Article";

export const werkzeuge: Article = {
    id: 3,
    title: "Werkzeuge",
    slug: "werkzeuge",
    content: [
        {
            content: [
                {
                    type: "multiple_choice",
                    data: {
                        question: "Für welche Handmaschine wird das abgebildete Werkzeug benötigt?",
                        options: ["Handkreissäge", "Handoberfräsmaschine", "Handhobelmaschine", "Lamellen-Nutfräser", "Stichsägemaschine"],
                        answer: ["Handoberfräsmaschine"],
                        image: "img/content/profilfraeser.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Es ist eine Hobelbank abgebildet. Welche Nummer zeigt auf die Hinterzange?",
                        options: ["1", "2", "6", "8", "10"],
                        answer: ["6"],
                        image: "img/content/hobelbank.png"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Das Freischneiden von Sägeblättern wird erreicht durch...",
                        options: ["die Bezahnung \"stark auf Stoß\".", "die Bezahnung auf \"Stoß auf Zug\".", "die Verdickung des Sägeblattrückens.", "das Schränken der Sägeblattzähne.", "langsamen Vorschub beim Sägen."],
                        answer: ["das Schränken der Sägeblattzähne."]
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Welches Anreiß- oder Messwerkzeug ist abgebildet?",
                        options: ["Gehrungswinkel", "Anschlagwinkel", "Schräger Winkel", "Schmiege", "Streichmaß"],
                        answer: ["Schmiege"],
                        image: "img/content/schmiege.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie heißt das Hobelteil mit der Nummer 3?",
                        options: ["Nase", "Keil", "Handschoner", "Schlagknopf", "Hobelmaul"],
                        answer: ["Keil"],
                        image: "img/content/hobel.png"
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "multiple_choice",
                    data: {
                        question: "Der abgebildete Bohrer ist ein ...",
                        options: ["Schlangenbohrer.", "Kunstbohrer.", "Forstnerbohrer.", "Versenker.", "Spiralbohrer mit Zentrierspitze."],
                        answer: ["Versenker."],
                        image: "img/content/versenker.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie groß ist der Keilwinkel bei einem Stecheisen?",
                        options: ["0°", "25°", "40°", "50°", "90°"],
                        answer: ["25°"],
                        image: "img/content/keilwinkel.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie heißt das abgebildete Anreißwerkzeug?",
                        options: ["Schmiege", "Streichmaß", "Gehrungswinkel", "Gliedermaßstab", "Laserpointer"],
                        answer: ["Streichmaß"],
                        image: "img/content/streichmaß.png"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie wird die dargestelle Holzwerkstoffplatte bezeichnet?",
                        options: ["Spanplatte", "Multiplexplatte", "MDF-Platte", "Furniersperrholzplatte", "Stäbchensperrholz"],
                        answer: ["Multiplexplatte"],
                        image: "img/content/multiplex.jpg"
                    }
                },
                {
                    type: "multiple_choice",
                    data: {
                        question: "Wie wird die dargestellte Schraube bezeichnet?",
                        options: ["Senkkopfschraube", "Flachkopfschraube", "Spanplattenschraube (SPAX)", "Schlüsselschraube", "Linsensenkholzschraube"],
                        answer: ["Multiplexplatte"],
                        image: "img/content/linsensenkholzschraube.jpg"
                    }
                }
            ]
        }
    ]
};
