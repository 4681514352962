import {Article} from "@/model/Article";

export const derBaum: Article = {
    id: 2,
    title: "Der Baum",
    slug: "der-baum",
    content: [
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Bestandteile"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Die Wurzel besteht aus Hauptwurzel und Nebenwurzeln, die den Baum in der Erde verankert. Mit Hilfe derer Wurzelhaare nimmt der Baum Wasser auf und damit  die zum Leben und Wachstum notwendigen Nährsalze, wie Calzium, Kalium, Magnesium und Eisen."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/baumkrone.jpg",
                        alt: "Baumkrone",
                        caption: "Baumkrone"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Der Stamm ist der Träger der Krone. In ihm werden die auf-und absteigende Säfte des des Baumes zwischen Krone und Wurzel transportiert."
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Nährstofftransport"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "In der Krone,speziell in den Blättern bzw.in den Nadeln, werden die im dem Wasser gelösten Nährsalze zu Nährstoffen für den Baum umgewandelt. Dabei nimmt der Baum durch kleine Spaltöffnungen auf der Unterseite der Blätter Kohlendioxid aus der Luft auf und zusammen mit den in Wasser gelösten Nährsalzen und mit Hilfe des Sonnenlichtes und des in den Blättern vorhandenen Blattgrüns werden aus körperfremden, anorganischen Stoffen organische, körpereigene Stoffe, wie Zucker und Stärke umgewandelt. Der dabei freiwerdende Sauerstoff wird durch die Spaltöffnungen der Blätter wieder freigegeben. Diesen Prozess nennt man auch Assimilation, bzw., da er nur bei Tageslicht stattfindet, auch Photosynthese."
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Aus dem Zucker und der Stärke bildet der Baum weitere körpereigene Stoffe wie Lignin, Zellulose, Harze, Fette und weitere Holzinhaltsstoffe. Bei  dieser Umwandlung benötigt der Baum Sauerstoff als Energiequelle. Dieser Prozess, bei dem Kohlendioxid freigesetzt wird, nennt man Atmung."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/derbaum.jpg",
                        alt: "Der Baum",
                        caption: ""
                    }
                }
            ]
        },
        {
            content: [
                {
                    type: "headline",
                    data: {
                        size: 2,
                        text: "Wachstum des Baumes"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "In unserer gemäßigten Zone  beginnt das Wachstum des Baumes im Frühjahr und dauert bis zum Spätsommer, bzw Herbst. Im Winter ruht das Wachstum. In den tropischen Regenwäldern findet ein ununterbrochendes Wachstum statt, sodaß die Holzbildung gleichmäßig ist."
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Man unterscheidet beim Baum zwischen dem Längenwachstum (primäres Wachstum) und dem Dickenwachstum (sekundäres Wachstum). Das Längenwachstum beginnt an den Enden der Stämme und Zweige, die aus zartwangigen Zellen aufgebaut sind. Aus diesen Zellen entwickeln sich durch Zellteilung neue Triebe, die weiter in die Länge wachsen. Die anfänglich grünen und weichen Triebe verholzen mit der Zeit."
                    }
                },
                {
                    type: "figure",
                    data: {
                        image: "img/content/querschnitt.jpg",
                        alt: "Querschnitt eines Baumstammes",
                        caption: "Querschnitt eines Baumstammes"
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Unter dem Dickenwachstum versteht man die Zunahme des Stammes. Dieses Wachstum erfolgt in der Kambiumschicht , die direkt hinter der Rinde liegt. Während der Wachstumsperiode werden drei Arten von Zellen erzeugt: Nach Außen Bastzellen, Zellen zur eigenen Vergrößerung und nach Innen Holzzellen."
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Die Bastzellen bilden nach außen die Borke, die mit zunehmendem Dickenwachstum aufreißt. Bei den Holzzellen unterscheidet man das Frühholz und das Spätholz, die wiederum zusammen die Jahresringe bilden. Das Frühholz wird im Frühjahr und im Sommer gebildet. Es hat dünnere Zellwandungen und größere Zellhohlräume und es ist heller. Das Spätholz ist dickwandig mit kleinen Zellhohlräumen. Es ist in der Regel schmaler und wesentlich dunkler als das Frühholz und wird im Spätsommer und Herbst gebildet."
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "An der Anzahl der Jahresringe läßt sich das Alter eines Baumes nachvollziehen. Die im Stamm außen liegenden Jahrringe dienen der Saft- bzw.Wasserführung des Baumes. Diesen Teil nennt man Splintholz. Bei sehr vielen Holzarten findet mit zunehmenden Alter eine Verkernung der inneren Jahrringe des Splintholzes statt, d.h. die Saft- und Wasserführung der Zellen wird eingestellt und an deren Stelle werden Holzinhaltsstoffe, wie Harz, Wachs, Gerbstoffe u.v.a. eingelagert. Im Vergleich zum Splintholz ist das Kernholz schwerer, dauerhafter und arbeitet weniger."
                    }
                },
                {
                    type: "paragraph",
                    data: {
                        text: "Holz, das auf diese Weise verkernt ist, sich jedoch farblich nicht vom Splintholz absetzt, nennt man Reifholz. Somit unterscheidet man Splintholzbäume, Kernholzbäume, Reifholzbäume und Kernreifholzbäume."
                    }
                }
            ]
        }
    ]
};
