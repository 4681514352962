import {ComputedRef, computed} from "vue";
import {Account} from "@/model/Account";

interface AccountData {
    account: ComputedRef<Account | undefined>
    setActiveAccount(account: Account): void
    signOut(): void
}

export function useAccount(): AccountData {
    const account = computed(() => {
        const active = localStorage.getItem("active_account");

        if(active) {
            return JSON.parse(active) || undefined;
        }

        return undefined;
    });

    function setActiveAccount(account: Account) {
        localStorage.setItem("active_account", JSON.stringify(account));
    }

    function signOut() {
        localStorage.removeItem("active_account");
    }

    return {
        account,
        setActiveAccount,
        signOut
    };
}
