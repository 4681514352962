export const toc = [
    {
        id: 1,
        name: "Grundlagen",
        children: [
            {
                id: 2,
                title: "Der Baum",
                slug: "der-baum"
            },
            {
                id: 4,
                title: "Bezeichnungen Holzarten",
                slug: "bezeichnungen-holzarten"
            }
        ]
    },
    {
        id: 5,
        name: "Fertigung",
        children: [
            {
                id: 6,
                title: "Schlitz- und Zapfenverbindung",
                slug: "schlitz-und-zapfen"
            },
        ]
    },
    {
        id: 8,
        name: "Prüfungsfragen",
        children: [
            {
                id: 9,
                title: "Werkstoff Holz",
                slug: "werkstoff-holz"
            },
            {
                id: 3,
                title: "Werkzeuge",
                slug: "werkzeuge"
            }
        ]
    }
];
